<template>
  <WeAppContainer>
    <WeCenter>
      <div class="card custom-header-bg">
        <div class="col text-center">
          <span class="big-text text-danger"><i class="fas fa-ban"></i></span>
          <h3 class="mb-4">Bu işlem için yetkiniz bulunmamaktadır</h3>
          <router-link to="/" class="btn btn-primary mb-5">
            <i class="fas fa-home"></i> Anasayfa
          </router-link>
        </div>
      </div>
    </WeCenter>
  </WeAppContainer>
</template>

<script>
export default {
  name: "Unauthorized",
};
</script>
